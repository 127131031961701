const $ = require('jquery');
const translate = require('../app/translate');

const PasswordStrengthValidator = new function () {
    this.inputEl = undefined;
    this.compareInputEl = undefined;
    this.parentEl = undefined;
    this.barEls = undefined;
    this.barLabelEl = undefined;
    this.btnEl = undefined;
    this.scoreTimer = undefined;
    this.barLabelCssList = ['col-xs-12', 'bar-label', 'text-center'];
    this.minPasswordLength = 8;
    this.barLabelTranslations = undefined;
    this.init = (selector) => {
        if (!selector) {
            selector = '#password-strength-validator';
        }

        const el = $(selector);

        if (!el || el.length === 0) {
            return;
        }

        this.parentEl = $(el[0]);

        this.inputEl = this.getElementByDataAttrSelector('data-input-el', true);
        this.compareInputEl = this.getElementByDataAttrSelector('data-compare-input-el', false);
        this.btnEl = this.getElementByDataAttrSelector('data-button-el', false);

        this.render();
        this.bindEvents();
    }
    this.getElementByDataAttrSelector = (attrKey, required) => {
        const elSelector = $(this.parentEl).attr(attrKey);

        if (!elSelector) {
            if (required) {
                console.error(`Missing required ${attrKey} element.`);
            }
            return;
        }

        const el = $(elSelector);

        if (!el || el.length === 0) {
            console.error(`Referenced element (${attrKey}) was not found with the following selector ${elSelector}`);
            return;
        }

        return el;
    }
    this.render = () => {
        this.barEls = {};

        ['red', 'yellow', 'green'].forEach((c) => {
            this.renderBar(c);
        });

        this.barLabelEl = this.appendDiv(this.barLabelCssList);
        this.appendDiv(['clearfix']);

        this.setButtonDisabled(true);
    }
    this.renderBar = (color) => {
        if (!color) {
            return;
        }

        this.barEls[color] = this.appendDiv(['col-xs-4', 'bar', `bar-${color}`]);
    }
    this.appendDiv = (classes) => {
        const el = $('<div></div>').addClass(classes);
        $(this.parentEl).append(el);

        return el;
    }
    this.bindEvents = () => {
        if (!this.inputEl) {
            return;
        }
        this.inputEl.on('keyup', this.keyUp);

        if (this.compareInputEl) {
            this.compareInputEl.on('keyup', this.keyUp);
        }
    }
    this.keyUp = () => {
        if (this.scoreTimer) {
            clearTimeout(this.scoreTimer);
        }
        // debounce evaluation
        this.scoreTimer = window.setTimeout(() => {
            this.scorePasswordStrength($(this.inputEl).val());
        }, 100);
    }
    this.setButtonDisabled = (disabled) => {
        if (this.btnEl) {
            this.btnEl.prop('disabled', disabled);
        }
    }
    this.scorePasswordStrength = (password) => {
        let score = 0;

        // if password does not fulfill requirements, we do not need to show a strength indication
        const validationResult = this.validatePassword(password,
            this.compareInputEl ? this.compareInputEl.val() : undefined);

        this.setButtonDisabled(validationResult !== undefined);

        if (validationResult !== undefined) {
            this.renderPasswordPasswordBar('invalid', validationResult);
            return;
        }

        // max. of 48 points
        score += Math.min(password.length, 16) * 3;

        // scoring for entropy, number of unique characters (max. 40 points)
        const unique = [...new Set(password.split(''))].length;
        score += Math.min(unique, 20) * 2;

        // penalize common words
        if (/1234|abcd|test|passwor|qwertz|hallo|guest|twingle/g.test(password)) {
            score -= 50;
        }

        score = Math.min(Math.max(0, score), 100);

        this.renderPasswordStrength(score);
    }
    this.renderPasswordStrength = (score) => {
        const color = score > (100 * 2 / 3) ? 'green' : score > (100 / 3) ? 'yellow' : score !== undefined ? 'red' : undefined;
        this.renderPasswordPasswordBar(color, undefined);
    }
    this.renderPasswordPasswordBar = (color, label) => {
        if (color) {
            this.parentEl.attr('data-password-strength', color);
        } else {
            this.parentEl.removeAttr('data-password-strength');
        }

        this.renderPasswordStrengthLabel(label || color);
    }
    this.renderPasswordStrengthLabel = (level) => {
        if (!this.barLabelEl) {
            return;
        }

        this.barLabelEl.text(this.getLabel(level) || '');
    }
    this.getLabel = (level) => {
        if (!level) {
            return undefined;
        }

        this.barLabelTranslations = this.barLabelTranslations || {
            'no_password_given': translate('validation.required').replace(':attribute', translate('validation.attributes').password),
            'do_not_match': translate('validation.custom')?.password?.confirmed,
            'to_short': translate('validation.min').string
                .replace(':attribute', translate('validation.attributes').password)
                .replace(':min', this.minPasswordLength),
            'missing_mixed': translate('validation.password').mixed,
            'missing_numbers': translate('validation.password').numbers,
            'missing_symbols': translate('validation.password').symbols,
            'green': translate('passwords.password_strength_validator_green'),
            'yellow': translate('passwords.password_strength_validator_yellow'),
            'red': translate('passwords.password_strength_validator_red')
        };

        return this.barLabelTranslations[level];
    }
    this.validatePassword = (password, confirmPassword) => {
        if (!password) {
            return 'no_password_given';
        }
        if (confirmPassword !== undefined && password !== confirmPassword) {
            return 'do_not_match';
        } else if (password.length < this.minPasswordLength) {
            return 'to_short';
        } else if (password.match(/[A-Z]/g) === null || password.match(/[a-z]/g) === null) {
            return 'missing_mixed';
        } else if (password.match(/[0-9]/g) === null) {
            return 'missing_numbers';
        } else if (password.match(/[\W_]/g) === null) {
            return 'missing_symbols';
        }

        return undefined;
    }
};

module.exports = PasswordStrengthValidator;