import PasswordStrengthValidator from './elements_jquery/password_strength_validator';
import TranslationLoader from "./utilities/translation_loader";

// public javascript
const $ = require('jquery');

$(function(){
  console.info('ready');

  $("body").on("input propertychange", ".form-group", function(e) {
    $(this).toggleClass("floating-value", !!$(e.target).val());
  }).on("focus", ".form-group", function() {
    $(this).addClass("floating-focus");
  }).on("blur", ".form-group", function() {
    $(this).removeClass("floating-focus");
  });

  window.setInterval(function(){
    $('.form-group:not(.form-group-floating)').each(function(){
      $(this).toggleClass("floating-value", !!$(this).find('input,select,textarea').val());
      $(this).addClass('form-group-floating');
    });
  }, 500);

  function togglePasswordView(el) {
    var formGroup = $(el).parents('.form-group');
    var input = formGroup.find('input');

    if (input.attr('type') == 'password') {
      formGroup.addClass('password-revealed');
      input.attr('type', 'text');
    } else {
      formGroup.removeClass('password-revealed');
      input.attr('type', 'password');
    }
  }

  $("body").on('click', '.toggle-password-view', function(e){
    togglePasswordView(e.currentTarget);

    const targetSel = $(e.currentTarget).attr('data-toggle-password');
    if(targetSel) {
      const targetEl = $(targetSel);
      if(targetEl && targetEl.length > 0) {
          togglePasswordView(targetEl[0]);
      }
    }
  });

  TranslationLoader.loadTranslation(Laravel.lang).then(() => {
    PasswordStrengthValidator.init();
  });

})