const _ = require('underscore');

/**
 * Helper Function
 *
 * Get translation for section.key from 'this.strings'
 *
 * @param {string} key 'the_array_key' OR 'app.the_array_key'
 * @param {string} section (optional) 'app', 'validation', ...
 * @returns {string}
 */
module.exports = function(key, section = false) {

  let strings = Laravel.strings;
  if (!section) {
    section = key.split('.')[0];
    key = key.split('.')[1];
  }

  if (_.isUndefined(strings[section])) {
    return '{' + section + '}';
  }
  if (_.isUndefined(strings[section][key])) {
    return '{' + section + '.' + key + '}';
  }

  return strings[section][key];
}