const $ = require('jquery');

const TranslationLoader = new function () {
    this.loadTranslation = (lang) => {
        let url = '/strings/' + lang;
        return $.getJSON(url, (strings) => {
            Laravel.strings = strings;
        });
    }
}

module.exports = TranslationLoader;